import * as React from "react";
import { graphql } from "gatsby";
import { TOptions } from "i18next";
import { BgImage } from "gbimage-bridge";
import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import i18n from "../i18n/i18n";
import Layout from "../components/Layout";
import PageStripe from "../components/PageStripe";
import SEO from "../components/SEO";

// markup
const About = ({ pageContext, data }) => {
  const { peopleImages, valueImages } = data;

  const t = (key: string, options?: TOptions) => {
    return i18n.t(key, { ...options, lng: pageContext.lang });
  };
  const peoplePictures = peopleImages.edges.sort(
    (edge1: any, edge2: any) =>
      edge1.node.base.split(".")[0].split("__")[0] -
      edge2.node.base.split(".")[0].split("__")[0]
  );
  const valueTexts: { name: string; description: string }[] = t(
    "aboutPage.VALUES.values",
    { returnObjects: true }
  ) as any;
  const peopleTexts: { name: string; role: string; order: number }[] = t(
    "aboutPage.PEOPLE.people",
    { returnObjects: true }
  ) as any;
  const sectionWidth = "w-4/5 md:w-8/12 lg:w-7/12 2xl:w-5/12 m-auto";

  const ValuesSection = ({ valueTexts, valueImages }) => {
    const slides = valueTexts.map((value, key) => {
      return (
        <SwiperSlide key={key}>
          <BgImage
            image={getImage(
              valueImages.edges[key].node.childImageSharp.gatsbyImageData
            )}
          >
            <div className="relative h-80 flex justify-center align-center">
              <div className="absolute inset-0 bg-gray-900 bg-opacity-75"></div>
              <div className={`slide-text-wrapper relative ${sectionWidth}`}>
                <h3
                  className={`inline-block uppercase text-white text-xl mb-8 border-b-4 border-gray-500`}
                >
                  {value.name}
                </h3>
                <p className="text-white text-lg">{value.description}</p>
              </div>
            </div>
          </BgImage>
        </SwiperSlide>
      );
    });
    return (
      <Swiper
        modules={[Autoplay, Pagination]}
        {...{
          onSwiper: (swiper) => swiper.$wrapperEl.addClass("items-center"),
          tag: "section",
          pagination: {
            el: ".swiper-pagination",
            type: "bullets",
            clickable: true,
            bulletActiveClass: "border-2 border-white h-4 w-4",
          },
          autoplay: {
            delay: 6000,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
          },
        }}
        className="w-full mt-10"
      >
        {slides}
        <span slot="container-end">
          <div className="swiper-pagination w-full flex items-center justify-center"></div>
        </span>
      </Swiper>
    );
  };

  return (
    <Layout pageContext={pageContext}>
      <SEO
        title={t("aboutPage.SEO.title")}
        description={t("aboutPage.SEO.description", {
          years: `${new Date().getFullYear() - 1993}`,
        })}
        pageContext={pageContext}
      ></SEO>
      <PageStripe name={t("aboutPage.title")} bgImage="about.jpg"></PageStripe>
      <section
        className={`flex items-center justify-center ${sectionWidth} mt-10`}
      >
        <p className="text-justify">{t("aboutPage.HERO.description")}</p>
      </section>
      <ValuesSection valueImages={valueImages} valueTexts={valueTexts} />
      <section className={`${sectionWidth} mt-16`}>
        <h2 className="text-3xl text-center uppercase">
          {t("aboutPage.PEOPLE.header")}
        </h2>
        <div className="grid grid-flow-row sm:grid-cols-3 gap-8 gap-y-20 my-16">
          {peoplePictures
            .sort(
              (edge1: any, edge2: any) =>
                edge1.node.base.split(".")[0].split("__")[0] -
                edge2.node.base.split(".")[0].split("__")[0]
            )
            .map((edge: any, key: number) => {
              const [_order, name] = edge.node.base.split(".")[0].split("__");
              return (
                <figure className="text-center" key={key}>
                  <GatsbyImage
                    image={getImage(edge.node) as any}
                    alt={name.replace(/_/g, " ")}
                    className="rounded-full w-40 h-40 xl:w-60 xl:h-60 flex justify-center items-center m-auto"
                  />
                  <figcaption className="font-medium pt-6 text-center">
                    <div className="text-black capitalize">
                      {peopleTexts[key].name}
                    </div>
                    <hr className="my-2 w-40 m-auto" />
                    <div className="text-gray-500 capitalize">
                      {peopleTexts[key].role}
                    </div>
                  </figcaption>
                </figure>
              );
            })}
        </div>
      </section>
    </Layout>
  );
};

export default About;

export const query = graphql`
  query {
    peopleImages: allFile(
      filter: { relativeDirectory: { regex: "/people/" } }
    ) {
      edges {
        node {
          base
          childImageSharp {
            gatsbyImageData(
              tracedSVGOptions: { blackOnWhite: true }
              placeholder: TRACED_SVG
            )
          }
        }
      }
    }
    valueImages: allFile(filter: { relativeDirectory: { regex: "/values/" } }) {
      edges {
        node {
          base
          childImageSharp {
            gatsbyImageData(
              tracedSVGOptions: { blackOnWhite: true }
              placeholder: TRACED_SVG
            )
          }
        }
      }
    }
  }
`;
